import React from 'react';
import styled from 'styled-components';
import Emoji from 'a11y-react-emoji';
import Map from '@common/Map';

import { Section, Container, Highlight } from '@components/global';
import MapPin from '@assets/icons/map-pin.svg';

const Location = () => (
  <Section accent style={{ paddingBottom: 0 }}>
    <Container>
      <Grid id="location">
        <div>
          <h2>Wo Sie mich finden</h2>
          <h3>hand, nail & foot</h3>
          <p>
            Vreni Messerli
            <br />
            Akazienweg 36
            <br />
            3184 Wünnewil
          </p>
          <h3 style={{ paddingTop: '48px' }}>
            Mit dem Auto <Emoji symbol="🚘" />
          </h3>
          <p>
            Sie haben die Möglichkeit, Ihr Auto{' '}
            <Highlight>kostenlos direkt vor dem Studio</Highlight> zu parken.
          </p>
          <h3>
            Mit den ÖV <Emoji symbol="🚊" />
          </h3>
          <p>
            Der Bahnhof Wünnewil befindet sich nur{' '}
            <Highlight>zwei Gehminuten</Highlight> von hand, nail & foot
            entfernt.
          </p>
        </div>
        <MapContainer
          style={{ minHeight: '280px', height: '100%', maxWidth: '600px' }}
        >
          <Map />
        </MapContainer>
      </Grid>
    </Container>
  </Section>
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-gap: 40px;
  text-align: left;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    > div {
      padding: 72px 72px 72px 0;
    }
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Image} {
          order: 2;
        }
    `}
  }
`;

const MapContainer = styled.figure`
  margin: 0;
  max-width: 480px;
  width: 100%;

  .marker {
    background-image: url('${MapPin}');
    background-size: cover;
    width: 32px;
    height: 32px;
  }
`;

export default Location;
