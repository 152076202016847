import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Emoji from 'a11y-react-emoji';

import { Section, Container, Highlight } from '@components/global';

const News = () => (
	<StaticQuery
		query={graphql`
			{
				corona: file(
					sourceInstanceName: { eq: "images" }
					name: { eq: "corona-2020" }
				) {
					childImageSharp {
						gatsbyImageData(
							width: 760
							quality: 90
							placeholder: NONE
							backgroundColor: "white"
							layout: CONSTRAINED
						)
					}
				}
				oct: file(
					sourceInstanceName: { eq: "images" }
					name: { eq: "dez-2021" }
				) {
					childImageSharp {
						gatsbyImageData(
							width: 760
							quality: 90
							placeholder: NONE
							backgroundColor: "white"
							layout: CONSTRAINED
						)
					}
				}
			}
		`}
		render={(data) => (
			<Section id="news">
				<Container>
					{/* <Grid style={{ marginBottom: '48px' }}>
            <div>
              <h2>
                Aktuell <Emoji symbol="⚠️" />
              </h2>
              <p>hand, nail & foot bleibt bis am 10. Juli geschlossen.</p>
            </div>
            <></>
          </Grid> */}
					<Grid inverse>
						<Image>
							<GatsbyImage
								image={data.oct.childImageSharp.gatsbyImageData}
								loading="eager"
								alt="Bild einer Behandlung"
							/>
						</Image>
						<div>
							<h2>
								Neu im Frühling <Emoji symbol="🌷" />
							</h2>
							<p>
								Jetzt ist Zeit für eine Fusspflege mit Peeling
								und Nagellack. Bei hand, nail & foot finden Sie
								alles, was sie für gesunde und schöne Füsse
								brauchen.
							</p>
							{/* <p style={{ paddingTop: "12px" }}>
								Zur Zeit im Angebot:{" "}
								<Highlight>Paraffinbad CHF 25.—</Highlight>
							</p> */}
						</div>
					</Grid>
					<Grid>
						<div>
							<h2>
								Ihre Gesundheit liegt mir am Herzen{" "}
								<Emoji symbol="🧡" />
							</h2>
							<p>
								Um Sie zu schützen, hält sich hand, nail & foot
								strikt an ein{" "}
								<Highlight>Hygienekonzept</Highlight>. Nach
								jedem Besuch wird gelüftet, gereinigt und alles
								desinfiziert.
							</p>
						</div>
						<Image>
							<GatsbyImage
								image={
									data.corona.childImageSharp.gatsbyImageData
								}
								alt="Desinfizierungsmittel"
							/>
						</Image>
					</Grid>
				</Container>
			</Section>
		)}
	/>
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: left;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
		props.inverse &&
		`
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
		props.inverse &&
		`
        ${Image} {
          order: 2;
        }
    `}
  }
`;

const Image = styled.figure`
  margin: 0;
  max-width: 480px;
  width: 100%;
`;

export default News;
