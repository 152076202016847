import React, { useRef, useEffect, useState } from 'react';
import mapboxgl from '!mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

// replace with your own Mapbox token
const MAPBOX_TOKEN = `${process.env.GATSBY_MAPBOX_TOKEN}`;

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

const Map = (props) => {
  const mapContainerRef = useRef(null);

  const [map, setMap] = useState(null);

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      accessToken: MAPBOX_TOKEN,
      style: 'mapbox://styles/lalalacha/ckfv3wfso0dmh1as7ndrp9pmw',
      center: [7.280314, 46.872492],
      zoom: 17,
      scrollZoom: false,
      attributionControl: false,
    });

    map.addControl(
      new mapboxgl.NavigationControl({ showCompass: false }),
      'top-right'
    );

    setMap(map);

    return () => map.remove();
  }, []);

  useEffect(() => {
    if (!map) return;

    var el = document.createElement('div');
    el.className = 'marker';

    new mapboxgl.Marker(el, { anchor: 'bottom' })
      .setLngLat([7.280314, 46.872492])
      .addTo(map);
  }, [map]);

  return (
    <div ref={mapContainerRef} style={mapContainerStyle}>
      {props.places && map}
    </div>
  );
};

export default Map;
