import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

import { Section, Container, Highlight } from '@components/global';

const Hours = () => (
	<StaticQuery
		query={graphql`
			{
				opening_hours: file(
					sourceInstanceName: { eq: "images" }
					name: { eq: "opening-hours" }
				) {
					childImageSharp {
						gatsbyImageData(
							quality: 100
							placeholder: NONE
							backgroundColor: "white"
							layout: CONSTRAINED
						)
					}
				}
			}
		`}
		render={(data) => (
			<Section accent id="hours">
				<Container>
					<Grid inverse>
						<Image>
							<GatsbyImage
								image={
									data.opening_hours.childImageSharp
										.gatsbyImageData
								}
								alt="Füsse am Strand"
							/>
						</Image>
						<div>
							<h2>Dann bin ich für Sie da</h2>
							<HoursGrid>
								<p>
									<b>Montag</b>
								</p>
								<p>13:30 - 18:00</p>
								<p></p>
								<p>
									<b>Dienstag</b>
								</p>
								<p>geschlossen</p>
								<p></p>
								<p>
									<b>Mittwoch</b>
								</p>
								<p>8:00 - 12:00</p>
								<p>13:30 - 17:00</p>
								<p>
									<b>Donnerstag</b>
								</p>
								<p>8:00 - 12:00</p>
								<p>13:30 - 17:00</p>
								<p>
									<b>Freitag bis Sonntag</b>
								</p>
								<p>geschlossen</p>
								<p></p>
							</HoursGrid>
							<br />
							{/* <p>
								<Highlight>
									Bis 10. Juli vorübergehend geschlossen.
								</Highlight>
							</p> */}
						</div>
					</Grid>
				</Container>
			</Section>
		)}
	/>
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 80px;
  text-align: left;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
		props.inverse &&
		`
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 32px;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    > div {
      padding: 72px 72px 72px 0;
    }
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
		props.inverse &&
		`
        ${Image} {
          order: 2;
        }
    `}
  }
`;

const HoursGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 16px;

  @media (max-width: ${(props) => props.theme.screen.lg}) {
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);

    /* Target the first item and every third item after that -> weekdays */
    > *:nth-child(3n+1) { 
        grid-column: span 2;
    }
  }
`;

const Image = styled.figure`
  margin: 0;
  max-width: 480px;
  width: 100%;
`;

export default Hours;
