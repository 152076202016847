import React from 'react';
import styled from 'styled-components';

import { Section, Container, Highlight } from '@components/global';
import { ReactComponent as ArrowDown } from '@assets/icons/arrow-down.svg';
import { ReactComponent as TwintLogo } from '@assets/logos/Twint_logo.svg';
import { ReactComponent as MaestroLogo } from '@assets/logos/Maestro_logo.svg';
import { ReactComponent as VPayLogo } from '@assets/logos/VPay_logo.svg';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import Emoji from 'a11y-react-emoji';
import FaqItem from '@common/FaqItem';

const OFFERS = [
	{
		key: "Hände",
		title: (
			<>
				<Emoji symbol="💅🏼" /> Hände
			</>
		),
		content: () => (
			<>
				<Grid>
					<GridOffer>
						Handpflege <b>Natural</b>
						<br />
						<OfferDetails>
							Nägel kürzen & formen, Nagelhaut, Nägel polieren
						</OfferDetails>
					</GridOffer>
					<GridPrice>55.—</GridPrice>
					<GridOffer>
						Handpflege <b>Chic</b>
						<br />
						<OfferDetails>
							Nägel kürzen & formen, Nagelhaut, Nägel lackieren
						</OfferDetails>
					</GridOffer>
					<GridPrice>75.—</GridPrice>
					<GridOffer>
						Handpflege <b>De Luxe mit Nagellack</b>
						<br />
						<OfferDetails>
							Nägel kürzen & formen, Nagelhaut, Handpeeling,
							Handmaske, Handmassage, Nägel lackieren
						</OfferDetails>
					</GridOffer>
					<GridPrice>110.—</GridPrice>
					<GridOffer>
						Handpflege <b>De Luxe mit Gellack</b>
						<br />
						<OfferDetails>
							Nägel kürzen & formen, Nagelhaut, Handpeeling,
							Handmaske, Handmassage, Gellack
						</OfferDetails>
					</GridOffer>
					<GridPrice>130.—</GridPrice>
					<GridOffer>
						<b>Gellack entfernen</b> ohne Erneuerung
						<br />
						<OfferDetails>
							inklusive Nägel kürzen & formen
						</OfferDetails>
					</GridOffer>
					<GridPrice>50.—</GridPrice>
					<GridOffer>
						Gel-/Acryl <b>Set Naturnägel</b>
						<br />
						<OfferDetails>French oder Fullcover</OfferDetails>
					</GridOffer>
					<GridPrice>150.—</GridPrice>
					<GridOffer>
						Gel-/Acryl <b>Set mit Verlängerung</b>
						<br />
						<OfferDetails>French oder Fullcover</OfferDetails>
					</GridOffer>
					<GridPrice>165.—</GridPrice>
					<GridOffer>
						Gellack inklusive French oder Fullcover
					</GridOffer>
					<GridPrice>95.—</GridPrice>
					<GridOffer>
						<b>Gel-/Acryl-Set</b> auffüllen
						<br />
						<OfferDetails>bis 2 Wochen</OfferDetails>
						<OfferDetails>bis 4 Wochen</OfferDetails>
						<OfferDetails>ab 4 Wochen</OfferDetails>
					</GridOffer>
					<GridPrice>
						<br />
						<OfferDetails>60.— </OfferDetails>
						<OfferDetails>98.— </OfferDetails>
						<OfferDetails>110.— </OfferDetails>
					</GridPrice>
					<GridOffer>Design nach Aufwand</GridOffer>
					<GridPrice>ab 15.—</GridPrice>
					<GridOffer>Nagelbeisserbehandlung</GridOffer>
					<GridPrice>140.—</GridPrice>
					<GridOffer>
						<b>Kurz-Refresh</b> der Nägel
						<br />
						<OfferDetails>Nägel kürzen, Fullcover</OfferDetails>
					</GridOffer>
					<GridPrice>65.—</GridPrice>
					<GridOffer>Paraffinbad</GridOffer>
					<GridPrice>30.—</GridPrice>
					<GridOffer>Reparatur pro Nagel</GridOffer>
					<GridPrice>20.—</GridPrice>
				</Grid>
				<Details>Weitere Angebote und Preise auf Nachfrage</Details>
			</>
		),
	},
	{
		key: "Füsse",
		title: (
			<>
				<Emoji symbol="🦶🏼" /> Füsse
			</>
		),
		content: () => (
			<>
				<Grid>
					<GridOffer>Fussnägel kürzen und formen</GridOffer>
					<GridPrice>45.—</GridPrice>
					<GridOffer>
						Fusspflege <b>Natural</b>
						<br />
						<OfferDetails>
							Nägel kürzen & formen, Nagelplatte reinigen,
							Hornhaut entfernen, Fussmassage
						</OfferDetails>
					</GridOffer>
					<GridPrice>70.—</GridPrice>
					<GridOffer>
						Fusspflege <b>Chic</b>
						<br />
						<OfferDetails>
							Nägel kürzen & formen, Nagelplatte reinigen,
							Hornhaut entfernen, Fussmassage mit Peeling und
							Fussbad
						</OfferDetails>
					</GridOffer>
					<GridPrice>95.—</GridPrice>
					<GridOffer>
						Fusspflege <b>Chic mit Nagellack</b>
						<br />
						<OfferDetails>
							Nägel kürzen & formen, Nagelplatte reinigen,
							Hornhaut entfernen, Fussmassage, Nägel lackieren
						</OfferDetails>
					</GridOffer>
					<GridPrice>95.—</GridPrice>
					<GridOffer>
						Fusspflege <b>Chic mit Gellack</b>
						<br />
						<OfferDetails>
							Nägel kürzen & formen, Nagelplatte reinigen,
							Hornhaut entfernen, Fussmassage, Gellack
						</OfferDetails>
					</GridOffer>
					<GridPrice>125.—</GridPrice>
					<GridOffer>
						Fusspflege <b>De Luxe mit Nagellack</b>
						<br />
						<OfferDetails>
							Grundpflege analog «Chic mit Nagellack» inklusive
							Fussbad & Peeling
						</OfferDetails>
					</GridOffer>
					<GridPrice>115.—</GridPrice>
					<GridOffer>
						Fusspflege <b>De Luxe mit Gellack</b>
						<br />
						<OfferDetails>
							Grundpflege analog «Chic mit Gellack» inklusive
							Fussbad & Peeling
						</OfferDetails>
					</GridOffer>
					<GridPrice>150.—</GridPrice>
					<GridOffer>
						Fusspflege <b>Express mit Gellack</b>
						<br />
						<OfferDetails>
							Nägel kürzen & formen, Nagelplatte reinigen, mit
							Gellack lackieren
						</OfferDetails>
					</GridOffer>
					<GridPrice>105.—</GridPrice>
					<GridOffer>
						<b>Gellack entfernen</b> ohne Erneuerung
						<br />
						<OfferDetails>
							inklusive Nägel kürzen & formen
						</OfferDetails>
					</GridOffer>
					<GridPrice>50.—</GridPrice>
					<GridOffer>Hüneraugen entfernen</GridOffer>
					<GridPrice>ab 45.—</GridPrice>
					<GridOffer>Warzenbehandlung</GridOffer>
					<GridPrice>ab 50.—</GridPrice>
					<GridOffer>Nagelspangen setzen</GridOffer>
					<GridPrice>ab 50.—</GridPrice>
				</Grid>
				<Details>Weitere Angebote und Preise auf Nachfrage</Details>
			</>
		),
	},
	{
		key: "Haarentfernung",
		title: (
			<>
				<Emoji symbol="🦵🏼" /> Haarentfernung
			</>
		),
		content: () => (
			<>
				<Grid>
					<GridOffer>Ober- oder Unterschenkel</GridOffer>
					<GridPrice>50.—</GridPrice>
					<GridOffer>Ganze Beine</GridOffer>
					<GridPrice>95.—</GridPrice>
					<GridOffer>Unter- oder Oberarme</GridOffer>
					<GridPrice>ab 40.—</GridPrice>
					<GridOffer>Ganze Arme</GridOffer>
					<GridPrice>55.—</GridPrice>
					<GridOffer>Achseln</GridOffer>
					<GridPrice>35.—</GridPrice>
					<GridOffer>Bikinizone</GridOffer>
					<GridPrice>40.—</GridPrice>
					<GridOffer>Bikinizone Brazilian</GridOffer>
					<GridPrice>60.—</GridPrice>
					<GridOffer>Rücken</GridOffer>
					<GridPrice>50.—</GridPrice>
					<GridOffer>Brust & Bauch</GridOffer>
					<GridPrice>50.—</GridPrice>
					<GridOffer>Kinn oder Oberlippe</GridOffer>
					<GridPrice>25.—</GridPrice>
					<GridOffer>Ganzes Gesicht</GridOffer>
					<GridPrice>40.—</GridPrice>
				</Grid>
				<Details>Weitere Angebote und Preise auf Nachfrage</Details>
			</>
		),
	},
	{
		key: "Wimpern & Brauen",
		title: (
			<>
				<Emoji symbol="✨" /> Wimpern & Brauen
			</>
		),
		content: () => (
			<>
				<Grid>
					<GridOffer>Augenbrauenkorrektur</GridOffer>
					<GridPrice>27.—</GridPrice>
					<GridOffer>Augenbrauen färben</GridOffer>
					<GridPrice>27.—</GridPrice>
					<GridOffer>Wimpern färben</GridOffer>
					<GridPrice>35.—</GridPrice>
					<GridOffer>Wimpernlifting inkl. Laminierung</GridOffer>
					<GridPrice>95.—</GridPrice>
				</Grid>
				<Details>Weitere Angebote und Preise auf Nachfrage</Details>
			</>
		),
	},
	{
		key: "Mikrodermabrasion",
		title: (
			<>
				<Emoji symbol="💎" /> Mikrodermabrasion
			</>
		),
		content: () => (
			<>
				<Grid>
					<GridOffer>
						Behandlung <b>Gesicht</b>
						<br />
						<OfferDetails>
							Reinigung, Mikrodermabrasion Behandlung, Ezympeeling
							Silber, Maske abgestimmt nach Hauttyp,
							Abschlusspflege inklusive Versiegelung & Schutz
						</OfferDetails>
					</GridOffer>
					<GridPrice>135.—</GridPrice>
					<GridOffer>
						Behandlung <b>Gesicht, Hals & Dekolleté</b>
						<br />
						<OfferDetails>
							Reinigung, Mikrodermabrasion Behandlung, Ezympeeling
							Silber, Maske abgestimmt nach Hauttyp,
							Abschlusspflege inklusive Versiegelung & Schutz
						</OfferDetails>
					</GridOffer>
					<GridPrice>155.—</GridPrice>
				</Grid>
			</>
		),
	},
];

const Offer = () => (
  <Section id="offers">
    <Container>
      <h1 style={{ marginBottom: 40 }}>Lassen Sie sich verwöhnen</h1>
      <div>
        {OFFERS.map(({ key, title, content }) => (
          <FaqItem title={title} key={key}>
            {content()}
          </FaqItem>
        ))}
      </div>
      <p>
        <Details>
          Alle Preise sind inkl. 8% MwSt. Ich bitte Sie, vereinbarte Termine
          einzuhalten oder{' '}
          <Highlight>innert 24 Stunden zu annullieren</Highlight>. Sie ersparen
          sich damit die Verrechnung der Behandlung.
        </Details>
        <PaymentDetails>
          Barzahlung oder <TwintLogo /> <MaestroLogo /> <VPayLogo />
        </PaymentDetails>
        <StyledAnchorLink to={`#contact`} title="Jetzt Termin vereinbaren">
          Jetzt Termin vereinbaren
          <StyledArrowDown />
        </StyledAnchorLink>
      </p>
    </Container>
  </Section>
);

const StyledAnchorLink = styled(AnchorLink)`
  color: inherit;
  text-decoration: none;
  font-weight: 400;
  color: ${props => props.theme.color.brand.regular};
  display: flex;
  align-items: center;
  margin-top: 40px;

  &:hover {
    color: ${props => props.theme.color.brand.dark};
  }
`;

const StyledArrowDown = styled(ArrowDown)`
  margin-left: 8px;
`;

const Details = styled.p`
  ${props => props.theme.font_size.xsmall};
  padding-top: 24px;
  svg {
    height: 24px;
    width: auto;
    margin: 0 0 0 12px;
  }
`;

const PaymentDetails = styled(Details)`
  display: flex;
  justify-items: center;
`;

const OfferDetails = styled.p`
  ${props => props.theme.font_size.xsmall};
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;

  div:nth-child(4n),
  div:nth-child(4n-1) {
    background-color: ${props => props.theme.color.secondary};
  }
`;

const GridOffer = styled.div`
  grid-column: 1/2;
  padding: 16px 12px;
  @media (max-width: ${props => props.theme.screen.xs}) {
    padding: 16px 6px;
  }
`;

const GridPrice = styled.div`
  grid-column: 2/3;
  padding: 16px 12px;
  text-align: right;
  white-space: nowrap;
  @media (max-width: ${props => props.theme.screen.xs}) {
    padding: 16px 6px;
  }
`;

export default Offer;
