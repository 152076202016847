import React from 'react';

import Layout from '@common/Layout';
import Header from '@sections/Header';
import News from '@sections/News';
import About from '@sections/About';
import Offer from '@sections/Offer';
import Location from '@sections/Location';
import Hours from '@sections/Hours';
import Contact from '@sections/Contact';

const IndexPage = () => (
  <Layout>
    <Header />
    <News />
    <About />
    <Offer />
    <Location />
    <Hours />
    <Contact />
  </Layout>
);

export default IndexPage;
