import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import Emoji from 'a11y-react-emoji';

import { Section, Container } from '@components/global';

const Contact = () => (
	<StaticQuery
		query={graphql`
			{
				img_footer: file(
					sourceInstanceName: { eq: "images" }
					name: { eq: "footer" }
				) {
					childImageSharp {
						gatsbyImageData(
							quality: 100
							placeholder: NONE
							backgroundColor: "white"
							layout: CONSTRAINED
						)
					}
				}
			}
		`}
		render={(data) => (
			<Section id="contact">
				<Container>
					<Grid>
						<div>
							<h2>Ich freue mich auf Sie!</h2>
							<p>
								Sie haben Fragen? Sie wollen einen Termin
								vereinbaren? <DesktopBr />
								Ich bin gerne via Telefon oder E-Mail für Sie
								da.
							</p>
							<p style={{ paddingTop: "48px" }}>
								Telefon <Emoji symbol="📞" />
								<br />{" "}
								<Link href="tel:+41792008226">
									+41 79 200 82 26
								</Link>
							</p>
							<br />
							<p>
								E-Mail <Emoji symbol="💌" />
								<br />{" "}
								<Link href="mailto:kontakt@handnailfoot.ch">
									kontakt@handnailfoot.ch
								</Link>
							</p>
						</div>
					</Grid>
					<Image>
						<GatsbyImage
							image={
								data.img_footer.childImageSharp.gatsbyImageData
							}
							style={{
								width: 580,
								maxWidth: "100%",
								marginBottom: -16,
							}}
							alt="Hände vor Blumen"
						/>
					</Image>
				</Container>
			</Section>
		)}
	/>
);

const Grid = styled.div`
  display: grid;
  text-align: center;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Image} {
          order: 2;
        }
    `}
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: ${props => props.theme.color.brand.regular};
  &:hover {
    color: ${props => props.theme.color.brand.dark};
  }
`;

const Image = styled.figure`
  display: flex;
  justify-content: center;
  margin: 0;
  padding-top: 72px;
  @media (max-width: ${props => props.theme.screen.md}) {
    padding-top: 12px;
  }
`;

const DesktopBr = styled.br`
  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`;

export default Contact;
