import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

import Emoji from 'a11y-react-emoji';
import { Section, Container, Highlight } from '@components/global';
import FaqItem from '../common/FaqItem';

const About = () => (
	<StaticQuery
		query={graphql`
			{
				portrait: file(
					sourceInstanceName: { eq: "images" }
					name: { eq: "portrait" }
				) {
					childImageSharp {
						gatsbyImageData(
							quality: 100
							placeholder: NONE
							backgroundColor: "white"
							layout: CONSTRAINED
						)
					}
				}
			}
		`}
		render={(data) => (
			<Section id="about" accent>
				<Container>
					<Grid>
						<div>
							<h2>Das erwartet Sie</h2>
							<p>
								Mein Name ist{" "}
								<Highlight>Vreni Messerli</Highlight> und seit
								mehr als 15 Jahren sind Nail-Design und
								Fusspflege meine Leidenschaft.
								<br />
								<br /> Beste Qualität und Ihre Zufriedenheit
								sind mein oberstes Ziel. In meinem Studio in
								Wünnewil werden Sie sich fernab vom Alltag vom
								ersten Moment an wohlfühlen.
								<br />
								<br /> Ich biete{" "}
								<Highlight>Hand- und Fusspflege</Highlight>{" "}
								nicht nur für Damen, sondern auch für Herren an.
								Einen besonderen Service stelle ich Kunden mit{" "}
								<Highlight>Problemnägeln</Highlight> (z.B.
								Nagelbeissern) zur Verfügung. Auch das passende
								Naildesign zu Ihrem Anlass oder{" "}
								<Highlight>Haarentfernung</Highlight>, werden
								von mir professionell umgesetzt. <br /> <br />
							</p>
						</div>
						<Image>
							<GatsbyImage
								image={
									data.portrait.childImageSharp
										.gatsbyImageData
								}
								alt="Portrait Vreni Messerli"
							/>
						</Image>
					</Grid>
					<FaqItem
						title={
							<>
								Mein Werdegang <Emoji symbol="📜" />
							</>
						}
						key="werdegang"
					>
						<Skills>
							<p>2023</p> <p>Microdermabrasion Schulung CNC</p>
							<p>2015</p> <p>Zwei Emmi-Nailart Zertifikate</p>
							<p>2014</p>{" "}
							<p>1. Rang beim Nailart-Wettbewerb von DOBI</p>
							<p>2006 - 2010</p>
							<p>
								Schullungsleiterin bei Doby jährliche
								Weiterbildungen Doby, Simon Keller, Rosselet,
								Akyado
							</p>
							<p>2006</p>{" "}
							<p>Q Label Prüfung (Prüfung zum Qualitätslabel)</p>
							<p>2005</p>{" "}
							<p>Eröffnung Studio hand, nail & foot</p>
							<p>2002 - 2005</p>
							<p>
								Diverse Ausbildungen in Nail- und
								Fusspflegeschulen
							</p>
							<p>2001</p>
							<p>Ausbildung Naildesignerin in Kaiseraugst</p>
						</Skills>
					</FaqItem>
				</Container>
			</Section>
		)}
	/>
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;
  text-align: left;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    grid-template-columns: 1fr 1fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Image} {
          order: 2;
        }
    `}
  }
`;

const Image = styled.figure`
  margin: 0;
  max-width: 480px;
  width: 100%;
`;

const Skills = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 24px 80px;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-gap: 24px 40px;
  }
`;

export default About;
