import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ReactComponent as ArrowDown } from '@assets/icons/arrow-down.svg';

import { Container } from '@components/global';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const Header = () => (
	<StaticQuery
		query={graphql`
			{
				header_image: file(
					sourceInstanceName: { eq: "images" }
					name: { eq: "header-img" }
				) {
					childImageSharp {
						gatsbyImageData(
							quality: 100
							placeholder: NONE
							backgroundColor: "white"
							layout: CONSTRAINED
						)
					}
				}
			}
		`}
		render={(data) => (
			<HeaderWrapper id="header">
				<Container>
					<Grid>
						<Image>
							<GatsbyImage
								image={
									data.header_image.childImageSharp
										.gatsbyImageData
								}
								loading="eager"
								alt="Header Bild"
							></GatsbyImage>
						</Image>
						<Text>
							<h1>
								Willkommen bei
								<br />
								<Brand>hand, nail & foot</Brand>
							</h1>
							<p>
								<StyledAnchorLink
									to={`#offers`}
									title="Zu den Angeboten"
								>
									Zu den Angeboten
									<StyledArrowDown />
								</StyledAnchorLink>
							</p>
						</Text>
					</Grid>
				</Container>
			</HeaderWrapper>
		)}
	/>
);

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.color.primary};
  padding-top: 104px;

  @media (max-width: ${props => props.theme.screen.md}) {
    padding-top: 168px;
  }
`;

const Image = styled.figure`
  width: 100%;
  margin: 0;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${props => props.theme.screen.md}) {
      width: 100%;
    }
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-gap: 64px;

  h1 {
    padding-bottom: 20px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 80px;

    > ${Image} {
      order: 2;
    }

    h1 {
      padding-bottom: 16px;
    }
  }

  a {
    font-weight: 400;
    text-decoration: none;
    color: ${props => props.theme.color.black.regular};
  }
`;

const Text = styled.div`
  justify-self: center;

  @media (max-width: ${props => props.theme.screen.md}) {
    justify-self: start;
  }
`;

const Brand = styled.div`
  color: ${props => props.theme.color.brand.regular};
  padding-bottom: 8px;
`;

const StyledAnchorLink = styled(AnchorLink)`
  color: inherit;
  text-decoration: none;

  display: flex;
  align-items: center;

  &:hover {
    color: ${props => props.theme.color.brand.dark};
  }
`;

const StyledArrowDown = styled(ArrowDown)`
  margin-left: 8px;
`;

export default Header;
